import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import weba11ygirl from "../../static//images/web/web-a11y-girl.svg"
import weba11yone from "../../static//images/web/web-a11y-one.svg"
import weba11ytwo from "../../static//images/web/web-a11y-two.svg"
import iconOne from "../../static//images/banner-icons/one.svg"
import iconTwo from "../../static//images/banner-icons/two.svg"
import iconThree from "../../static//images/banner-icons/three.svg"
import iconFour from "../../static//images/banner-icons/four.svg"
import iconFive from "../../static//images/banner-icons/five.svg"
import bookcover from "../../static//images/web/manjula-book.jpeg"
import manjula from "../../static//images/web/manjula.png"
import useScript from "../hooks/useScript"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const IndexPage = () => {
  useScript("https://manjuladube.ck.page/27341f6377/index.js")

  return (
    <Layout>
      <SEO title="Landing Page" />
      <GatsbySeo
        openGraph={{
          description: "Learn to build accessible websites with Manjula Dube",
          url: "https://weba11y101.com/",
          type: "book",
          book: {
            releaseDate: "2020-10-22T11:08:13",
            isbn: "#",
            authors: ["https://www.manjuladube.dev/about"],
            tags: ["Web", "Accessiblity", "Book", "Guide", "React", "Development"],
          },
          images: [
            {
              url: "https://weba11y101.com/static/images/web/book-cover.png",
              width: 595,
              height: 842,
              alt: "Building accessible websites 101 Book Cover",
            },
          ],
        }}
      />

      <section className={"page-header"}>
        <div className={"thumbnail-banner"}>
          <img alt={"letter d and f icon"} className={"div1"} src={iconOne} />
          <img alt={"eye icon"} className={"div2"} src={iconTwo} />
          <img alt={"reorder icon"} className={"div3"} src={iconThree} />
          <img alt={"search with letter icon"} className={"div4"} src={iconFour} />
          <img alt={"man speaking icon"} className={"div5"} src={iconFive} />
        </div>
        <h1>Building accessible websites 101</h1>
        <p>
          Learn to build accessible websites with me.
          <br />
        </p>
      </section>

      <section className={"container"}>
        <div className={"features"}>
          <div className={"feature__item"}>
            <div className={"row"}>
              <div className={"col-6 first"}>
                <div className={"thumbnail"}>
                  <img alt={"The book will consist of 120 Pages of Guide Book"} src={weba11ygirl} />
                </div>
              </div>

              <div className={"col-6"}>
                <div className={"feature__content"}>
                  <p className="content-title">120 Pages of Guide Book</p>
                  <p className="content-description">
                    {" "}
                    Guide to building accessible websites This book is my personal learnings over the past 2 years which
                    will help you create accessible websites and write semantic HTML from the start. I will be sharing
                    all my learnings and code examples for each topic.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={"feature__item"}>
            <div className={"row"}>
              <div className={"col-6"}>
                <div className={"feature__content"}>
                  <p className="content-title">Updated Content Promised</p>
                  <p className="content-description">
                    Web accessibility and WCAG is evolving and coming with new guidelines, standards and tools to build
                    accessible web apps. I will keep updating this book with new guidelines as and when they come.
                  </p>
                </div>
              </div>

              <div className={"col-6 first"}>
                <div className={"thumbnail"}>
                  <img alt={"Updated Content Promised in the book"} src={weba11yone} />
                </div>
              </div>
            </div>
          </div>

          <div className={"feature__item"}>
            <div className={"row"}>
              <div className={"col-6 first"}>
                <div className={"thumbnail"}>
                  <img alt={"Code Examples for each topic in detail"} src={weba11ytwo} />
                </div>
              </div>

              <div className={"col-6"}>
                <div className={"feature__content"}>
                  <p className="content-title">Code Examples</p>
                  <p className="content-description">
                    Each of the topic consist of detailed code example that will walk you through the topics in depth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={"feature__item"}>
        <div className="learn-section">
          <h2 className="book-for"> Who is this book for?</h2>
          <p className="book-information">
            {" "}
            Remember, accessibility is a team effort. Every person has a role to play. <br /> Developer like you can
            bring this change within your Team.
          </p>

          <div className="learn-section-grid">
            <p className="tile-content"> Boost Up your A11y knowledge. </p>
            <p className="tile-content"> Start Building Inclusive Products</p>
            <p className="tile-content"> A11y Case Studies from Fortune 500 Startups</p>
            <p className="tile-content"> Much More </p>
          </div>
        </div>
      </section>
      <section id="about-book" className={"bg-color"}>
        <div className={"container"}>
          <div className={"integration-guid"}>
            <h2>What’s inside the Book ?</h2>

            <div className={"content"}>
              <p>
                This book is for anyone who has prior knowledge of HTML and CSS and want to start building accessible
                web apps. This book is for anyone who cares about developing things that is accessible to everyone. This
                book is also for developers who want to stay updated with guidelines and tools required to build and
                accessible web app.
              </p>
              <img alt={"Book Cover Girl"} src={bookcover} />

              <p>For the past 2 years I've been actively working on web accessibility.</p>
              <p>
                I certainly value all the companies giving immense importance to accessibility at an early stage of
                their product. This book will sum up all of my learnings till now and will make sure you understand web
                accessibility as not some tool to install over night. I'll show you rather how to write more semantic
                HTML from the start. When I started learning accessibility I had a notion that it's just about blind
                users. Whereas, it's so much more than that! It includes people from all walks of life and disabilities.
                Be it physical disabilities like vision impairment, hard of hearing, or intellectual, cognitive,
                developmental disabilities like Autism Spectrum disorder, Down Syndrome and also mental conditions and
                situational impairment. It's vital to build inclusive websites in this age for everyone with love and
                passion! I decided to write a book as I couldn’t find all the resources at one place when I had started
                out. This book is the culmination of my learnings on web accessibility from the past two years.
              </p>

              <br />
              <br />
              <br />
              <p>
                {" "}
                The book cover is designed by awesome <a href="https://twitter.com/salonimhapsekar">Saloni Mhapsekar</a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="call-to-action" className={"call-to-action"}>
        <div className={"container"}>
          <div className={"call-to-action__content"}>
            <h2>Order Now!!</h2>
            <p>Join over 500 Developers</p>
            <p> Get Good with Digital Accessibility.Help make the web a better place. </p> <br />
            <p>
              <b>
                If you don't like the book, I will definitely give you a refund, email me at manjula@geekabyte.tech .
              </b>{" "}
            </p>
            <div className={"button"}></div>
          </div>

          <div className={"button"}>
            <a href="#!" class="paddle_button" data-product="634295" data-theme="none">
              Order Today!{" "}
            </a>
          </div>
        </div>
      </section>
      <section className={"call-to-action"}>
        <div className={"container"}>
          <div className={"call-to-action__content"}>
            <h2>Are you student ?</h2>
            <p>If you are student signup, I will give you the book for free until 31st Dec 2020.</p>
            <a
              class="signup"
              rel="noopener noreferrer"
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSfCO2ZU-ZrDU28tkjAeTk3xmAq8YcrYM0Czh7SMd6MwxoFp3Q/viewform"
            >
              Sign up{" "}
            </a>
          </div>
        </div>
      </section>

      <section className={"features"}>
        <div className={"feature__item"}>
          <div className={"row_author"}>
            <div className={"col-4 first"}>
              <div className={"thumbnail"}>
                <img alt={"Manjula Profile"} src={manjula} />
              </div>
            </div>

            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>Manjula Dube - Author</h2>
                <p>
                  {" "}
                  I am Software Engineer & teacher. I'm a world renowned tech speaker.I am from India currently living
                  in Berlin with my husband Sahil Mhapsekar. <br /> <br />I work at N26 as Web Engineer. I am Founder of
                  Geekabyte that aims to deliver in person tech workshops on Web Development & organise international
                  conferences, React India & JS Conf India. I'm also a Google Developer Expert. I have been obsessed
                  with coding ever since I graduated out of college. <br /> <br />
                  I am founding member of Mumbai Women Coders that aims to encourage more women in tech & provide an
                  avenue into the technology world. I love contributing to open source in my free time. I love
                  Javascript, React & my family ❤️ In coming years I see my self teaching people to code. <br /> <br />
                </p>
                <a href="https://www.manjuladube.dev/">Know more about me on - Personal Website</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
